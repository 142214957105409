<template>
  <div class="genre-drop">
    <div class="genre-drop-items" v-for="(genre,index) in genres" :key="index">
      <p @click="sendGenre(genre)">{{genre.toLowerCase()}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["genres"],
  data() {
    return {};
  },
  methods: {
    sendGenre(data) {
      this.$emit("genreItem", data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.genre-drop {
  position: absolute;
  background-color: $clr-dark-gd2;
  border-radius: 10px;
  height: 180px;
  overflow-y: auto;
  &-items {
    padding: 0.5rem;
    font-family: $font-regular;
    p {
      color: $clr-light-gd4;
    }
  }
}
</style>